import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import apolloProvider from "./plugins/apollo";
import Carousel3d from "vue-carousel-3d";

import { ref, onValue } from "firebase/database";

// eslint-disable-next-line no-unused-vars
import helpers from "./mixins/helpers";
import Meta from "vue-meta";

import { auth, parseJwt, db } from "./services/firebase";
import gql from "graphql-tag";

Vue.use(Carousel3d);

const initializeAuth = new Promise((resolve) => {
  auth.onAuthStateChanged(async function (user) {
    if (user) {
      let u = parseJwt(user.accessToken);
      if (u["https://hasura.io/jwt/claims"]) {
        localStorage.setItem("exp", u["exp"]);
        localStorage.setItem("token", user.accessToken);

        let { data } = await apolloProvider.defaultClient.query({
          query: gql`
            query fullUserByID($id: String!) {
              user_by_pk(id: $id) {
                billing_address1
                billing_address2
                billing_city
                billing_country
                billing_state
                billing_zipcode
                bio
                created_at
                email
                facebook_handle
                favorite_medium
                favorite_species_biome
                handle
                id
                instagram_handle
                name
                phone
                photo_url
                shipping_address1
                shipping_address2
                shipping_city
                shipping_country
                shipping_state
                shipping_zipcode
                stripe_connect_id
                twitter_handle
                updated_at
              }
            }
          `,
          variables: {
            id: user.uid,
          },
        });
        store.commit("load_user_from_db", data.user_by_pk);

        store.state.user.role =
          u["https://hasura.io/jwt/claims"]["x-hasura-default-role"];
        store.state.user.is_login = true;
        resolve(true);
      } else {
        // Claims were not set, we need to wait on claims before doing anything else
        const metadataRef = ref(db, "metadata/" + user.uid + "/refreshTime");

        onValue(metadataRef, async (snapshot) => {
          if (!snapshot.exists) return;
          const t = await user.getIdToken(true);
          console.log(parseJwt(t));

          localStorage.setItem("token", t);

          let { data } = await apolloProvider.defaultClient.query({
            query: gql`
              query fullUserByID($id: String!) {
                user_by_pk(id: $id) {
                  billing_address1
                  billing_address2
                  billing_city
                  billing_country
                  billing_state
                  billing_zipcode
                  bio
                  created_at
                  email
                  facebook_handle
                  favorite_medium
                  favorite_species_biome
                  handle
                  id
                  instagram_handle
                  name
                  phone
                  photo_url
                  shipping_address1
                  shipping_address2
                  shipping_city
                  shipping_country
                  shipping_state
                  shipping_zipcode
                  stripe_connect_id
                  twitter_handle
                  updated_at
                }
              }
            `,
            variables: {
              id: user.uid,
            },
          });
          store.commit("load_user_from_db", data.user_by_pk);

          store.state.user.role =
            u["https://hasura.io/jwt/claims"]["x-hasura-default-role"];
          store.state.user.is_login = true;

          resolve(true);
        });
      }
    } else {
      localStorage.removeItem("token");
      resolve(true);
    }
  });
});

store.state.user.initialized = initializeAuth;

Vue.use(Meta, {
  refreshOnceOnNavigation: true,
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  apolloProvider,
  render: (h) => h(App),
}).$mount("#app");
