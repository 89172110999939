import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyCsNQ7GJMC1gMI4zGiuxX0CZH_l9-Xp5Mk",
  authDomain: "wecan-art.firebaseapp.com",
  databaseURL: "https://wecan-art-default-rtdb.firebaseio.com",
  projectId: "wecan-art",
  storageBucket: "wecan-art.appspot.com",
  messagingSenderId: "455849734744",
  appId: "1:455849734744:web:94aaf92bf2bd10ba6a502d",
  measurementId: "G-8P4RM6XGJ9",
};

const firebaseApp = initializeApp(firebaseConfig);
const auth = getAuth(firebaseApp);
const db = getDatabase(firebaseApp);

function parseJwt(token) {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
}

export { auth, parseJwt, db };
