import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    next_route: null,
    user: {
      initialized: false,
      is_login: false,
      role: "",
      id: null,

      // Everything below here is filled in by database
      billing_address2: null,
      billing_address1: null,
      billing_city: null,
      billing_country: null,
      billing_state: null,
      billing_zipcode: null,
      bio: null,
      created_at: null,
      email: null,
      facebook_handle: null,
      favorite_medium: null,
      favorite_species_biome: null,
      handle: null,
      instagram_handle: null,
      name: null,
      phone: null,
      photo_url: null,
      shipping_address1: null,
      shipping_address2: null,
      shipping_city: null,
      shipping_country: null,
      shipping_state: null,
      shipping_zipcode: null,
      stripe_connect_id: null,
      twitter_handle: null,
      updated_at: null,
    },
    snackbar: {
      show: false,
      text: "",
    },
  },
  getters: {},
  mutations: {
    load_user_from_db(state, payload) {
      state.user.id = payload.id;
      state.user.billing_address2 = payload.billing_address2;
      state.user.billing_address1 = payload.billing_address1;
      state.user.billing_city = payload.billing_city;
      state.user.billing_country = payload.billing_country;
      state.user.billing_state = payload.billing_state;
      state.user.billing_zipcode = payload.billing_zipcode;
      state.user.bio = payload.bio;
      state.user.created_at = payload.created_at;
      state.user.email = payload.email;
      state.user.facebook_handle = payload.facebook_handle;
      state.user.favorite_medium = payload.favorite_medium;
      state.user.favorite_species_biome = payload.favorite_species_biome;
      state.user.handle = payload.handle;
      state.user.instagram_handle = payload.instagram_handle;
      state.user.name = payload.name;
      state.user.phone = payload.phone;
      state.user.photo_url = payload.photo_url;
      state.user.shipping_address1 = payload.shipping_address1;
      state.user.shipping_address2 = payload.shipping_address2;
      state.user.shipping_city = payload.shipping_city;
      state.user.shipping_country = payload.shipping_country;
      state.user.shipping_state = payload.shipping_state;
      state.user.shipping_zipcode = payload.shipping_zipcode;
      state.user.stripe_connect_id = payload.stripe_connect_id;
      state.user.twitter_handle = payload.twitter_handle;
      state.user.updated_at = payload.updated_at;
    },
    set_snackbar(state, payload) {
      state.snackbar.show = true;
      state.snackbar.text = payload;
    },
    set_next_route(state, payload) {
      state.next_route = payload;
    },
  },
  actions: {},
  modules: {},
});
