import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store/index";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: () =>
      import(/* webpackChunkName: "Home" */ "../views/HomeView.vue"),
  },
  {
    path: "/about",
    name: "about",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
  {
    path: "/artist/:handle",
    name: "artist",
    component: () =>
      import(/* webpackChunkName: "artist" */ "../views/ArtistView.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/LoginView.vue"),
  },
  {
    path: "/signup",
    name: "signup",
    component: () =>
      import(/* webpackChunkName: "signup" */ "../views/SignupView.vue"),
  },
  {
    path: "/art",
    name: "art",
    component: () =>
      import(/* webpackChunkName: "art" */ "../views/ArtListView.vue"),
  },
  {
    path: "/art/:id/:slug",
    name: "art-detail",
    component: () =>
      import(/* webpackChunkName: "art-detail" */ "../views/ArtDetailView.vue"),
  },
  {
    path: "/blog",
    name: "blog",
    component: () =>
      import(/* webpackChunkName: "blog" */ "../views/BlogListView.vue"),
  },
  {
    path: "/blog/:slug",
    name: "blog-detail",
    component: () =>
      import(
        /* webpackChunkName: "blog-detail" */ "../views/BlogDetailView.vue"
      ),
  },
  {
    path: "/account",
    name: "account",
    meta: {
      loginRequired: true,
    },
    component: () =>
      import(/* webpackChunkName: "account" */ "../views/AccountView.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeResolve(async (to, from, next) => {
  if (to.meta.loginRequired && (await store.state.user.initialized)) {
    if (!store.state.user.is_login) {
      store.commit("set_next_route", to.path);
      return next("/login");
    }
  }

  next();
});

export default router;
