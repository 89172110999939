var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('v-navigation-drawer',{attrs:{"fixed":"","temporary":"","dark":"","right":"","clipped":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-list',{attrs:{"nav":"","dense":""}},[_c('v-list-item-group',{attrs:{"active-class":"text--accent-4"},model:{value:(_vm.group),callback:function ($$v) {_vm.group=$$v},expression:"group"}},[_c('v-img',{staticClass:"mr-2 mt-5 mb-5",attrs:{"alt":"AnnuityCheck","contain":"","src":require("@/assets/logo.png"),"transition":"scale-transition","width":"100%"}}),_vm._l((_vm.$store.state.user.is_login
            ? _vm.loggedInMenu
            : _vm.loggedOutMenu),function(tab,index){return _c('v-list-item',{key:index,attrs:{"link":"","to":tab.custom_action ? '' : tab.to},on:{"click":function($event){$event.stopPropagation();tab.custom_action ? tab.custom_action() : null}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(tab.icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(tab.text))])],1)],1)}),(_vm.$store.state.user.role === 'admin')?_c('div',[_c('v-divider',{staticClass:"mt-2 mb-2"}),_c('h3',[_vm._v("Admin")]),_vm._l((_vm.admin),function(tab,index){return _c('v-list-item',{key:index,attrs:{"link":"","to":tab.to}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(tab.icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(tab.text))])],1)],1)})],2):_vm._e()],2)],1)],1),_c('v-app-bar',{attrs:{"app":""}},[_c('div',{staticClass:"d-flex align-center"},[_c('router-link',{attrs:{"to":"/"}},[_c('v-img',{attrs:{"alt":"weCAN","contain":"","src":require("@/assets/logo.png"),"transition":"scale-transition","width":"120"}})],1)],1),_c('v-spacer'),(_vm.$vuetify.breakpoint.smAndDown)?_c('v-app-bar-nav-icon',{on:{"click":function($event){$event.stopPropagation();_vm.drawer = !_vm.drawer}}}):_c('v-tabs',{attrs:{"right":"","optional":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_vm._l((_vm.$store.state.user.is_login
          ? _vm.loggedInMenu
          : _vm.loggedOutMenu),function(tab,index){return _c('v-tab',{key:index,attrs:{"to":tab.custom_action ? '' : tab.to},on:{"click":function($event){$event.stopPropagation();tab.custom_action ? tab.custom_action() : null}}},[_vm._v(" "+_vm._s(tab.text)+" ")])}),(_vm.$store.state.user.acl === 10)?_c('v-menu',{attrs:{"bottom":"","offset-y":"","open-on-hover":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"align-self-center mr-4",attrs:{"text":""}},'v-btn',attrs,false),on),[_vm._v(" Admin "),_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-menu-down ")])],1)]}}],null,false,4252645028)},[_c('v-list',_vm._l((_vm.admin),function(tab,index){return _c('v-list-item',{key:index,attrs:{"link":"","to":tab.to}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(tab.text)+" ")])],1)],1)}),1)],1):_vm._e()],2)],1),_c('v-main',[_c('router-view')],1),_c('v-snackbar',{scopedSlots:_vm._u([{key:"action",fn:function(ref){
          var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.$store.state.snackbar.show = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.$store.state.snackbar.show),callback:function ($$v) {_vm.$set(_vm.$store.state.snackbar, "show", $$v)},expression:"$store.state.snackbar.show"}},[_vm._v(" "+_vm._s(_vm.$store.state.snackbar.text)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }